
import Orderable from '@/components/Orderable'

export default {
  components: {
    Orderable
  },

  props: {
    delivery: {
      required: true,
      type: Object
    },
    order: {
      required: true,
      type: Object
    }
  },

  data: () => ({
    show: false
  }),

  computed: {
    minStoreShipmentTime () {
      if (this.delivery.items.length) {
        return this.delivery.items.reduce((prev, curr) => {
          return prev.store.shipment_min < curr.store.shipment_min ? prev : curr
        }).store.shipment_min
      } else {
        return 0
      }
    },

    maxStoreShipmentTime () {
      if (this.delivery.items.length) {
        return this.delivery.items.reduce((prev, curr) => {
          return prev.store.shipment_max > curr.store.shipment_max ? prev : curr
        }).store.shipment_max
      } else {
        return 0
      }
    },

    shipTodayAddDays () {
      // Add 1 day if the current time is more than the shipment_before time
      return this.$moment(this.order.created_at).locale(this.$i18n.locale).format('HH:mm:ss') < this.delivery.method.shipment_before ? 0 : 1
    },

    from () {
      return this.$moment(this.order.created_at).locale(this.$i18n.locale)
        .add(this.shipTodayAddDays, 'days')
        .add(this.delivery.store.shipment_min, `${this.delivery.store.shipment_min_unit.name}s`)
        .add(this.store.stockStatus.shipment_time_min, `${this.store.stockStatus.shipment_time_min_unit.name}s`)
        .add(this.delivery.method.delivery_time_min, `${this.delivery.method.delivery_time_min_unit.name}s`)
    },

    to () {
      return this.$moment(this.order.created_at).locale(this.$i18n.locale)
        .add(this.shipTodayAddDays, 'days')
        .add(this.delivery.store.shipment_max, `${this.delivery.store.shipment_max_unit.name}s`)
        .add(this.store.stockStatus.shipment_time_max, `${this.store.stockStatus.shipment_time_max_unit.name}s`)
        .add(this.delivery.method.delivery_time_max, `${this.delivery.method.delivery_time_max_unit.name}s`)
    },

    shipmentTimeOfDelivery () {
      if (this.to.year() === this.from.year() && this.to.month() === this.from.month() && this.to.date() === this.from.date()) {
        return this.to.format('ll')
      } else if (this.to.year() === this.from.year() && this.to.month() === this.from.month() && this.to.date() !== this.from.date()) {
        return `${this.from.format('DD')} — ${this.to.format('ll')}`
      } else if (this.to.year() === this.from.year() && this.to.month() !== this.from.month() && this.to.date() !== this.from.date()) {
        return `${this.from.format('DD MMM')} — ${this.to.format('ll')}`
      } else {
        return `${this.from.format('ll')} — ${this.to.format('ll')}`
      }
    },

    store () {
      return this.delivery.items[0].store
    },

    index () {
      return this.order.deliveries.findIndex(delivery => delivery.id === this.delivery.id)
    }
  }
}
