
import { mapGetters } from 'vuex'

export default {
  props: {
    cheaperTogetherProductStore: {
      required: true,
      type: Object
    },
    product: {
      type: Object,
      default () {
        return {}
      },
      required: true
    },
    warranties: {
      type: Array,
      default () {
        return []
      },
      required: true
    },
    addons: {
      type: Array,
      default () {
        return []
      },
      required: true
    },
    schemaOrg: {
      required: false,
      default () {
        return false
      },
      type: Boolean
    }
  },

  data: () => ({
    warranty: {
      types: []
    },
    addon: {
      types: []
    },
    conditionStatusLimit: 2
  }),

  computed: {
    ...mapGetters({
      cart: 'cart/cart',
      cartModal: 'cart/modal',
      country: 'country',
      currency: 'currency',
      settings: 'settings'
    }),

    itemPropSchemaOrgName () {
      const data = {}

      if (this.schemaOrg) {
        data.itemprop = 'name'
      }

      return data
    },

    conditionNew () {
      return ['new'].includes(this.cheaperTogetherProductStore.conditionStatus.type)
    },

    link () {
      return this.localePath({
        name: 'slug',
        params: {
          slug: this.cheaperTogetherProductStore.product.page.slug
        }
      })
    },

    storesLink () {
      return this.localePath({
        name: 'slug',
        params: {
          slug: this.cheaperTogetherProductStore.product.page.slug
        },
        query: {
          tab: 'stores'
        }
      })
    },

    reviewsLink () {
      return this.localePath({
        name: 'slug',
        params: {
          slug: this.cheaperTogetherProductStore.product.page.slug
        },
        query: {
          tab: 'stores'
        }
      })
    },

    cover () {
      if (this.cheaperTogetherProductStore.images.length > 0) {
        return {
          src: `${this.cheaperTogetherProductStore.images[0].folder}/cart/lazy/${this.cheaperTogetherProductStore.images[0].url}`,
          placeholder: `${this.cheaperTogetherProductStore.images[0].folder}/cart/src/${this.cheaperTogetherProductStore.images[0].url}`
        }
      } else {
        return {
          src: this.$config.noImage,
          placeholder: this.$config.noImage
        }
      }
    },

    productCover () {
      if (this.product.images.length > 0) {
        return {
          src: `${this.product.images[0].folder}/cart/lazy/${this.product.images[0].url}`,
          placeholder: `${this.product.images[0].folder}/cart/src/${this.product.images[0].url}`
        }
      } else {
        return {
          src: this.$config.noImage,
          placeholder: this.$config.noImage
        }
      }
    },

    addOnPrice () {
      let addonsPrice = 0
      let warrantiesPrice = 0

      this.addons.forEach((addon) => {
        addonsPrice += (Object.keys(addon).length ? addon.price : 0)
      })

      this.warranties.forEach((warranty) => {
        warrantiesPrice += (Object.keys(warranty).length ? warranty.price : 0)
      })

      return warrantiesPrice + addonsPrice
    },

    oldPrice () {
      if (this.cheaperTogetherProductStore.price.old) {
        return this.cheaperTogetherProductStore.price.old + this.productPrice
      }

      return 0
    },

    price () {
      return this.cheaperTogetherProductStore.price.current + this.productPrice
    },

    productPrice () {
      return this.product.store.price.current + this.addOnPrice
    }
  },

  methods: {
    async addCheaperTogetherToCart () {
      const cheaperTogetherWith = { item: this.product, itemStore: this.product.store, warranties: this.warranties, addons: this.addons }

      this.$store.dispatch('cart/setCartType', 'cart')
      await this.$store.dispatch('cart/addToCart', cheaperTogetherWith)
      await this.$store.dispatch('cart/addToCart', { item: this.cheaperTogetherProductStore.product, itemStore: this.cheaperTogetherProductStore, warranties: [this.cheaperTogetherProductStore.warranty], addons: [], cheaperTogetherWith })
      this.$store.dispatch('cart/setCartModalActive', true)
    }
  }
}
