
export default {
  props: {
    payment: {
      required: true,
      type: Object
    },
    order: {
      required: true,
      type: Object
    }
  },

  methods: {
    getPaymentMethodCommissionDescription (method) {
      if (method.commission.type === 'percent' && method.commission.value > 0) {
        return `${method.commission.value} %`
      } else if (method.commission.type === 'amount' && method.commission.value > 0) {
        return `${method.commission.value} ${this.order.currency.sign}`
      } else {
        return ''
      }
    }
  }
}
