
export default {
  props: {
    installment: {
      required: true,
      type: Object
    },
    order: {
      required: true,
      type: Object
    }
  },

  methods: {

  }
}
